import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ClientsLogo from "../../assets/img/clients-app-isologo2.png";
import { useAuthProvider, useRedirect } from 'react-admin';
import BackgroundSpinner from '../../components/spinner/BackgroudSpinner';

const Login = () => {
	// eslint-disable-next-line
	const loginUrl = `${process.env.REACT_APP_AUTH_URL}/authn/login/${process.env.REACT_APP_AUTH_APP}/`;
	const auth = useAuthProvider();
	const redirect = useRedirect();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [hasAccess, setHasAccess] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const setPermissionsOnLocalStates = async() => {
			try {
				await auth.getPermissions().then((data) => {
					const roles = data.roles;
					if(roles.includes('admin')) return setHasAccess(true);
					return setHasAccess(false);
				});
				await auth.checkAuth().then(data => {
					setIsAuthenticated(data);
				});
			} catch (error) {
				throw new Error('There was a problem during authentication.');
			} finally {
				setIsLoading(false);
			}
		};
		setPermissionsOnLocalStates();
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			if (hasAccess) {
				setShowMessage(false);
				redirect('/clients');
			} else {
				setShowMessage(true);
			}
		} return;
	}, [isAuthenticated, hasAccess]);

	const handleLoading = () => {
		setIsLoading(true);
		redirect(loginUrl);
	};
	
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			height="100vh"
			width="100%"
			flexDirection="column"
		>
			<Box component="img" src={ClientsLogo} alt="Clients App logo" width={200}/>
			<Box display="flex" flexDirection="column" sx={{ml: 5}} textAlign="center" gap={5}>
				<Typography variant="h3">Clients App</Typography>
				{showMessage && <Typography variant="h5" color='red'>Access denied</Typography>}
				<Button variant="contained" onClick={handleLoading} >Login</Button>
			</Box>
			<BackgroundSpinner open={isLoading} />
		</Box>
	);
};

export default Login;
