import React from 'react';
import {
	ReferenceField,
	TextField,
	useShowContext,
} from 'react-admin';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import {
	Box,
	Typography,
	Divider,
	Card,
	CardContent
} from '@mui/material';
// import NoteList from '../../notes/NoteList';
// import ProjectContactsList from './ProjectContactsList';
import ProjectAside from './ProjectAside';
import PositionSkillsList from '../../positions/details/PositionSkillsList';
import LoadedPositionGridList from '../../positions/list/LoadedPositionList';

const ProjectDetailContent = () => {
	const { record: project, isLoading } = useShowContext();
	if (isLoading) return null;
	return (
		<Box mt={2} display="flex">
			<Box flex="1">
				<Card>
					<CardContent>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="start"
						>
							<Box
								width={80}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<CreateNewFolderIcon color="disabled"/>
								<ReferenceField
									source='client_id'
									reference="clients"
									link="show"
								>
									<TextField
										variant='subtitle2'
										sx={{textAlign: 'center'}}
										source="name"
										align="center"
									/>
								</ReferenceField>
							</Box>
							<Box ml={2} flex="1">
								<Typography variant="h5">{project.project_name}</Typography>
								<Box display="flex" mt={2}>
									<Box display="flex" mr={5} flexDirection="column">
										<Typography color="textSecondary" variant="body2">
                                            Status
										</Typography>
										<Typography variant="subtitle1">
											{project.status.name}
										</Typography>
									</Box>
									<Box display="flex" mr={5} flexDirection="column">
										<Typography color="textSecondary" variant="body2">
                                            Invoice Code
										</Typography>
										<Typography variant="subtitle1">
											{project.inv_code}
										</Typography>
									</Box>
									{/*<Box display="flex" mr={5} flexDirection="column">
										<Typography color="textSecondary" variant="body2">
                                            Project Contact
										</Typography>
										<Typography variant="subtitle1">
											<ReferenceField
												source='project_contact'
												reference="contacts"
												link="show"
											>
												<TextField
													variant='subtitle2'
													sx={{textAlign: 'center'}}
													source="contact_name"
													align="center"
												/>
											</ReferenceField>
										</Typography>
									</Box>*/}
									<Box display="flex" mr={5} flexDirection="column">
										<Typography color="textSecondary" variant="body2">
                                            Account Manager
										</Typography>
										<Typography variant="subtitle1">
											{project.account_manager}
										</Typography>
									</Box>
									<Box display="flex" mr={5} flexDirection="column">
										<Typography color="textSecondary" variant="body2">
                                            Sales Person
										</Typography>
										<Typography variant="subtitle1">
											{project.sales_person}
										</Typography>
									</Box>
									<Box display="flex" mr={5} flexDirection="column">
										<Typography color="textSecondary" variant="body2">
                                            Tech Stack
										</Typography>
										<PositionSkillsList skills={project.tech_stack}/>								
									</Box>
								</Box>
								{/* <Box mt={2} mb={2}>
									<Box
										display="flex"
										mr={5}
										flexDirection="column"
										minHeight={48}
									>
										<Typography color="textSecondary" variant="body2">
                                            Contacts
										</Typography>
										<ReferenceManyField
											target='project_id'
											reference={`projects/${project.id}/contacts`}>
											<ProjectContactsList />
										</ReferenceManyField>
									</Box>
								</Box> */}
								<Divider />
								{/* <Box mt={2}>
									<ReferenceManyField
										reference={`projects/${project.id}/notes`}
										target="project_id"
									>
										<NoteList reference="project-notes"/>
									</ReferenceManyField>
								</Box> */}
								<LoadedPositionGridList specificProject={true} projectPositions={project.positions} />
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
			<ProjectAside link='edit' />
		</Box>
	);
};

export default ProjectDetailContent;
