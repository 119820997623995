import React, { useEffect, useState } from 'react';
import { TextInput, SelectInput, BooleanInput, required, regex} from 'react-admin';
import { Stack } from '@mui/material';

import { timeZones } from '../../../constants/SelectOptions';
import { useWatch } from "react-hook-form";

const ContactForm = ({ isOnClientPage = false, clientOptions = [], projectOptions = [], isFromSpecificClientPage = false }) => {

	const clientSelectInput = useWatch({
		name: 'client_id',
	});

	const phoneInput = useWatch({
		name: 'phone',
	});

	const preferredContactWayOptions = [
		{
			id: 'Email',
			name: 'Email'
		},
		{
			id: 'Phone',
			name: 'Phone',
			disabled: !phoneInput
		}
	];

	const [selectProjectOptions, setSelectProjectOptions] = useState([]);

	useEffect(() => {
		setSelectProjectOptions(projectOptions.filter((project) => project.client_id == clientSelectInput));
	}, [clientSelectInput]);


	return (
		<>
			{
				(!isOnClientPage) && (
					<Stack direction="row" sx={{ gap: '2%' }}>
						<SelectInput
							source="client_id"
							choices={clientOptions}
							validate={required()}
							fullWidth
							disabled={isFromSpecificClientPage}
						/>
						<SelectInput
							source="project_id"
							choices={selectProjectOptions}
							validate={required()}
							fullWidth
							disabled={!clientSelectInput}
						/>
					</Stack>
				)
			}
			<TextInput source="contact_name" validate={required()} fullWidth />
			<TextInput source="position" validate={required()} fullWidth />
			<Stack direction="row" sx={{ gap: '2%' }}>
				<TextInput
					source="email"
					validate={required()}
					fullWidth
				/>
				<TextInput
					source="phone"
					validate={regex(/^[0-9,+,-]*$/, 'Must be a valid Phone Number')}
					fullWidth
				/>
				<SelectInput
					source="preferred_contact_way"
					label="Preferred way to be contacted"
					choices={preferredContactWayOptions}
					validate={required()}
					fullWidth
				/>
			</Stack>
			<TextInput source="location" validate={required()} fullWidth />
			<SelectInput source="time_zone" choices={timeZones} validate={required()} fullWidth />
			<BooleanInput source="is_main" label="Is main contact?" fullWidth />
		</>
	);
};

export default ContactForm;
