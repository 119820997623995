export const industries = [
	'Health care',
	'Food industry',
	'Automotive',
	'Non Profit',
	'Human Talent',
	'Entertainment',
	'Information Technologies',
	'Sales Engineering',
	'Consulting'
].map(industry => ({ id: industry, name: industry }));

export const statuses = [
	'Active',
	'Cold',
	'Lead'
].map(status => ({ id: status, name: status }));

export const projectStatuses = [
	'Active',
	'Waiting for client',
	'Waiting for ioet',
	'Waiting for sow',
	'Closed',
	'Completed'
].map(status => ({ id: status, name: status }));

export const timeZones = [
	'GMT -12',
	'GMT -11',
	'GMT -10',
	'GMT -9',
	'GMT -8',
	'GMT -7',
	'GMT -6',
	'GMT -5',
	'GMT -4',
	'GMT -3',
	'GMT -2',
	'GMT -1',
	'GMT 0',
	'GMT +1',
	'GMT +2',
	'GMT +3',
	'GMT +4',
	'GMT +5',
	'GMT +6',
	'GMT +7',
	'GMT +8',
	'GMT +9',
	'GMT +10',
	'GMT +11',
	'GMT +12',
].map(timeZone => ({ id: timeZone, name: timeZone }));

export const roleProfiles = [
	'Staffed position',
	'Mentor',
	'Shadow',
	'Training',
	'Internal',
	'+1 visible',
	'+1 invisible',
	'+1 consulting',
	'+1',
	'Consultant',
	'Tech lead',
	'Waiting for assignment',
	'Support'
].map(roleProfile => ({ id: roleProfile, name: roleProfile }));


export const roleStatuses = [
	'Filled',
	'Open',
	'Vacated',
	'Urgent',
	'Needs review',
	'Coming soon',
	'Low priority',
	'Waiting onboarding',
	'Closed',
	'On hold',
	'Proposal',
	'Waiting for answer',
	'Internal open'
].map(roleStatus => ({ id: roleStatus, name: roleStatus }));

export const projectRanks = [
	'1',
	'2',
	'3',
	'4',
	'5'
].map(projectRank => ({ id: projectRank, name: projectRank }));

export const skills = [
	'JavaScript',
	'HTML/CSS',
	'Python',
	'SQL',
	'Java',
	'Node.js',
	'TypeScript',
	'C#',
	'Bash/Shell',
	'C++',
	'PHP',
	'C',
	'PowerShell',
	'Go',
	'Kotlin',
	'Rust',
	'Ruby',
	'Dart',
	'Assembly',
	'Swift',
	'R',
	'VBA',
	'Matlab',
	'Groovy',
	'Objective-C',
	'Scala',
	'Perl',
	'Haskell',
	'Delphi',
	'Clojure',
	'Elixir',
	'LISP',
	'Julia',
	'F#',
	'Erlang',
	'APL',
	'Crystal',
	'COBOL',
	'MySQL',
	'PostgreSQL',
	'SQLite',
	'MongoDB',
	'Microsoft SQL Server',
	'Redis',
	'MariaDB',
	'Firebase',
	'Elasticsearch',
	'Oracle',
	'DynamoDB',
	'Cassandra',
	'IBM DB2',
	'Couchbase',
	'AWS',
	'Google Cloud Platform',
	'Microsoft Azure',
	'Heroku',
	'DigitalOcean',
	'IBM Cloud or Watson',
	'Oracle Cloud Infrastructure',
	'React.js',
	'jQuery',
	'Express',
	'Angular',
	'Vue.js',
	'ASP.NET Core',
	'Flask',
	'ASP.NET',
	'Django',
	'Spring',
	'Angular.js',
	'Laravel',
	'Ruby on Rails',
	'Gatsby',
	'FastAPI',
	'Symfony',
	'Svelte',
	'Drupal',
	'.NET Framework',
	'NumPy',
	'.NET Core / .NET 5',
	'Pandas',
	'TensorFlow',
	'React Native',
	'Flutter',
	'Keras',
	'Qt',
	'Torch/PyTorch',
	'Cordova',
	'Apache Spark',
	'Hadoop',
	'Git',
	'Docker',
	'Yarn',
	'Kubernetes',
	'Unity 3D',
	'Ansible',
	'Terraform',
	'Xamarin',
	'Unreal Engine',
	'Puppet',
	'Deno',
	'Chef',
	'Flow',
	'Pulumi',
	'Windows',
	'Linux-based',
	'MacOS',
	'Windows Subsystem for Linux (WSL)',
	'BSD',
	'Android',
	'iOS',
	'Selenium',
	'Cypress',
	'Playwright',
	'Katalon studio',
	'Appium',
	'Robot Framework',
	'Postman',
	'K6',
	'Jmeter',
	'Gatling',
	'Soapui',
	'LoadRunner',
	'W3C validator',
	'Project Management',
	'Business Analysis'
].map(skill => ({ id: skill, name: skill }));
