import React from 'react';
import { Box } from '@mui/material';
import { RecordContextProvider, useListContext } from 'react-admin';
import ClientCard from './ClientCard';

const LoadedClientGridList = () => {
	const { data, isLoading } = useListContext();

	if (isLoading) return null;

	return (
		<Box display="flex" flexWrap="wrap" width="100%" gap={1} marginTop='5px'>
			{data?.map(client => (
				<RecordContextProvider key={client?.id} value={client}>
					<ClientCard />
				</RecordContextProvider>
			))}
		</Box>
	);
};

export default LoadedClientGridList;
