import * as React from 'react';
import {
	useRecordContext
} from 'react-admin';
import {
	Button
} from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Link as RouterLink } from 'react-router-dom';

const CreateRelatedProjectButton = () => {
	const client = useRecordContext();
	return (
		<Button
			component={RouterLink}
			to="/projects/create"
			state={{ record: { client_id: client.id } }}
			color="primary"
			variant="contained"
			size="small"
			startIcon={<CreateNewFolderIcon />}
		>
              Add project
		</Button>
	);
};

export default CreateRelatedProjectButton;
