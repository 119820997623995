import authService from "./authService";

const authProvider = {
	login: async () => {},
	checkAuth: async () => {
		try {
			if (window.location.hash.includes('/login') || window.location.pathname.includes('/login')) {
				await authService.getLoggedUser();
				return Promise.resolve(true);
			} else return Promise.resolve();
		} catch (error) {
			return Promise.reject(false);
		}
	},
	checkError: (error) => {
		const { status } = error;
		if (status === 401 || status === 403) {
			// eslint-disable-next-line prefer-promise-reject-errors
			return Promise.reject({ logoutUser: false });
		}
		return Promise.resolve();
	},
	getPermissions: async () => {
		try {
			return await authService.getUserPermissions();
		} catch (e) {
			return { roles: [], featureToggles: [] };
		}
	},
	logout: async () => {
		try {
			await authService.logout();
		} catch (error) {
			return Promise.reject();
		}
		return Promise.resolve();
	},
	getIdentity: async () => {
		try {
			const loggedUser = await authService.getLoggedUser();
			const currentUser = loggedUser.json;
			const userToStorage = {
				id: currentUser?.sub,
				fullName: currentUser?.name,
				avatar: currentUser?.picture,
			};
			return userToStorage;
		} catch (e) {
			return Promise.reject();
		}
	},
};

export default authProvider;
