import React from 'react';
import { useListContext } from 'react-admin';

import LoadingContactList from './LoadingContactList';
import LoadedContactList from './LoadedContactList';

export const ContactGridList = () => {
	const { isLoading } = useListContext();
	return isLoading ? <LoadingContactList /> : <LoadedContactList />;
};
