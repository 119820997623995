import React from 'react';
import {
	List,
	Pagination,
	useGetIdentity
} from 'react-admin';


import { ContactGridList } from '../../components/contacts/list/ContactGridList';
import ContactListActions from '../../components/contacts/list/ContactListActions';

const ContactList = () => {
	const { identity } = useGetIdentity();
	if (!identity) return null;
	return (

		<List
			actions={<ContactListActions />}
			pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
			perPage={25}
			component="div"
		>
			<ContactGridList />
		</List>
	);
};

export default ContactList;
