import React, { useEffect, useState } from 'react';
import { TextInput, DateInput, SelectInput, NumberInput, required, SelectArrayInput } from 'react-admin';
import { Stack } from '@mui/material';
import { keepOnlyLetters } from '../../../utils/formatters';	
import { projectRanks, skills } from '../../../constants/SelectOptions';
import { useWatch } from "react-hook-form";


const PositionForm = ({clientOptions = [], projectOptions = [], positionRolesOptions=[], positionStatusOptions=[], positionProfilesOptions=[], editMode=false}) => {	
	const clientSelectInput = useWatch({
		name: 'client_id',
	});

	const [selectProjectOptions, setSelectProjectOptions] = useState([]);

	useEffect(() => {
		setSelectProjectOptions(projectOptions.filter((project) => project.client_id == clientSelectInput));
	}, [clientSelectInput, setSelectProjectOptions]);

	return (
		<>
			<Stack direction="row" sx={{ gap: '2%' }}>
				<SelectInput source="client_id" choices={clientOptions} validate={required()} fullWidth/>
				<SelectInput source="project_id" choices={selectProjectOptions} validate={required()} fullWidth disabled={!clientSelectInput}/>
			</Stack>
			<Stack direction="row" sx={{ gap: '2%' }}>
				<TextInput source="position_name" format={keepOnlyLetters} parse={keepOnlyLetters} validate={required()} fullWidth disabled={!clientSelectInput} />
				<SelectInput source="position_role_id" label="Standard Role" choices={positionRolesOptions} validate={required()}  fullWidth disabled={!clientSelectInput}/>
			</Stack>
			<Stack direction="row" sx={{ gap: '2%' }}>
				{/* <TextInput 
					source="inv_code" 
					labe='Inv. Code'
					validate={regex(/^[0-9,-]*$/, 'Must be a valid Invoice Code')}
					fullWidth disabled={!clientSelectInput} 
					defaultValue={null}
					required={false}
				/> */}
				<NumberInput validate={required()} source="person_time" label='Person time (%)' min='0' max='100' step={10} defaultValue={0} fullWidth disabled={!clientSelectInput} />
				<NumberInput validate={required()} source={editMode ? "standard_role.rank" : "role_rank"} label='Role rank' min='0' max='100' step={10} defaultValue={0} fullWidth disabled={!clientSelectInput} />

			</Stack>
			<Stack direction="row" sx={{ gap: '2%' }}>
				<DateInput
					source="position_start_date"
					fullWidth
					disabled={!clientSelectInput}
					defaultValue={null}
					required={false}
				/>
				<DateInput
					source="position_end_date"
					fullWidth
					disabled={!clientSelectInput}
					defaultValue={null}
					required={false}
				/>
			</Stack>
			<Stack direction="row" sx={{ gap: '2%' }}>
				<SelectInput
					source="position_profile"
					choices={positionProfilesOptions || []}
					validate={required()}
					fullWidth
					disabled={!clientSelectInput}
				/>
				<SelectInput
					source="position_status"
					choices={positionStatusOptions || []}
					validate={required()}
					fullWidth
					disabled={!clientSelectInput}
				/>
				<SelectInput
					source="project_rank"
					choices={projectRanks}
					fullWidth
					disabled={!clientSelectInput}
					defaultValue={null}
					required={false}
				/>
			</Stack>
			<SelectArrayInput
				source="position_skills"
				label='Position skills'
				choices={skills}
				fullWidth
				disabled={!clientSelectInput}
				defaultValue={[]}
				required={false}
			/>
		</>
	);
};

export default PositionForm;
