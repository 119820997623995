import React from 'react';
import {
	ReferenceManyField,
	useShowContext,
	useGetList
} from 'react-admin';
import {
	Box,
	Card,
	CardContent,
	Typography
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import NoteList from '../../notes/NoteList';
import { ContactAside } from './ContactAside';


const ContactDetailContent = () => {
	const { record: contact, isLoading } = useShowContext();
	const { data: clients } = useGetList('clients');

	const getClientName = () => {
		const client = clients?.filter(client => client.id == contact.client_id);
		return client ? (client[0]?.name || '') : '';
	};

	if (isLoading || !contact) return null;
	return (
		<Box mt={2} display="flex">
			<Box flex="1">
				<Card>
					<CardContent>
						<Box display="flex" mb={1} alignItems="center">
							<BusinessIcon color="disabled" sx={{ mr: 1 }} />
							<Box ml={2} flex="1">
								<Typography variant="h5">{contact.contact_name}</Typography>
								<Typography variant="h7">{contact.position} at {`${getClientName()}`}</Typography>
							</Box>
						</Box>
						<ReferenceManyField
							reference={`contacts/${contact.id}/notes`}
							target="contact_id"
						>
							<NoteList reference="contact-notes" />
						</ReferenceManyField>
					</CardContent>
				</Card>
			</Box>
			<ContactAside link='edit' />
		</Box>
	);
};



export default ContactDetailContent;
