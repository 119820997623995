import React from 'react';
import {
	TopToolbar,
	CreateButton
} from 'react-admin';

const ContactListActions = () => {
	return (
		<TopToolbar>
			<CreateButton
				variant="contained"
				label="New Contact"
				sx={{ marginLeft: 2 }}
			/>
		</TopToolbar>
	);
};

export default ContactListActions;
