import React from 'react';
import {
	List,
	Pagination,
	useGetIdentity
} from 'react-admin';
import { ClientCardsGrid } from '../../components/clients/list/ClientCardsGrid';
import ClientListActions from '../../components/clients/list/ClientListActions';

const ClientList = () => {
	const { identity } = useGetIdentity();
	if (!identity) return null;
	return (

		<List
			actions={<ClientListActions />}
			pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
			perPage={15}
			component="div"
		>
			<ClientCardsGrid />
		</List>
	);
};

export default ClientList;
