import React from 'react';
import { Edit, Form, Toolbar, useGetList, DeleteWithConfirmButton, SaveButton, useUpdate, useGetRecordId, useNotify, useRedirect, useEditController } from 'react-admin';
import { Box, CardContent, Stack, Avatar } from '@mui/material';

import WorkIcon from '@mui/icons-material/Work';
import PositionForm from '../../components/positions/create/PositionForm';
import PositionAside from '../../components/positions/details/PositionAside';

const PositionEdit = () => {
	
	const { data: clients } = useGetList('clients');
	const { data: projects } = useGetList('projects');
	const { data: positionRoles } = useGetList('position-roles');
	const clientsOptions = clients?.map(client => ({ id: client.id, name: client.name }));
	const projectsOptions = projects?.map(project => ({ id: project.id, name: project.project_name, client_id: project.client_id }));
	const positionRolesOptions = positionRoles?.map(positionRole => ({ name: positionRole.name, id: positionRole.id }));

	const { data: positionProfiles } = useGetList('position-profile');


	const { data: positionStatusData} = useGetList('position-status');


	const { record } = useEditController();
	const recordId = useGetRecordId();
	const notify = useNotify();
	const redirect = useRedirect();
	const [update] = useUpdate();


	const onSubmit = (formData) => {
		update(
			'positions',
			{
				id: recordId,
				data: {
					id: recordId,
					project_id: formData.project_id !== record.project_id ? formData.project_id : "",
					project_name: "",
					position_name:formData.position_name,
					position_skills: formData.position_skills,
					client_id: "",
					person_name: "",
					position_start_date: formData.position_start_date,
					position_end_date: formData.position_end_date,
					person_time: formData.person_time,
					position_profile: formData.position_profile !== record.position_profile ? formData.position_profile : "",
					position_status: formData.position_status !== record.position_status ? formData.position_status : "",
					project_rank: formData.project_rank,
					created_date: "",
					is_visible: true,
					position_role_id: formData.position_role_id !== record.position_role_id ? formData.position_role_id : "",
					inv_code: formData.inv_code,
					role_rank: formData.standard_role.rank,
					standard_role: { "id" : "", "name": "", "rank": 0 },
					client_name: ""
				}

			},
			{
				onSuccess: () => {
					notify('Updated', { type: 'success' });
					redirect('/positions');
				},
			}
		);
	};

	return (
		<Edit
			aside={<PositionAside link="show" />}
			actions={false}
			redirect="show"
		>
			<Form onSubmit={onSubmit} warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<WorkIcon />
						</Avatar>
						<Box ml={2} flex="1" maxWidth={796}>
							<PositionForm
								clientOptions={clientsOptions}
								projectOptions={projectsOptions}
								positionRolesOptions={positionRolesOptions}
								positionProfilesOptions={positionProfiles}
								positionStatusOptions={positionStatusData}
								editMode={true}
							/>
						</Box>
					</Stack>
				</CardContent>
				<Toolbar style={{justifyContent:"space-between"}}>
					<SaveButton sx={{alignSelf: 'center', mx: 2}} />
					<DeleteWithConfirmButton 
						confirmTitle={`Delete position?`}
						confirmContent="You will not be able to recover this record. Are you sure?"
						sx={{alignSelf: 'center', mx: 2}}
					/>
				</Toolbar>
			</Form>
		</Edit>
	);
};

export default PositionEdit;
