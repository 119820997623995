import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import { Create, Form, Toolbar, useCreate, SaveButton, useNotify, useRedirect, useGetList } from 'react-admin';
import { CardContent, Divider, Stack, Avatar, Box } from '@mui/material';

import ClientForm from '../../components/clients/create/ClientForm';
{/* TODO: Uncomment when information is retrieved from BFF*/}
// The code commented in this file is due to the contact entity is not implemented, so while it happens we can't create clients with a contact related.
// import ContactForm from '../../components/contacts/create/ContactForm';
const ClientCreate = () => {
	const [create, { isLoading }] = useCreate();
	const notify = useNotify();  
	const redirect = useRedirect();
	const { data: StatusData, isLoading: isLoadingStatus } = useGetList(
		'client-status',
	);


	const onSubmit = (formData) => {
		const clientRequest = {
			name: formData.name,
			status: formData.status.id,
			industry: formData.industry,
			projects: ''
		};
		create(
			'clients',
			{
				data: clientRequest
			},
			{
				onSuccess: () => {
					notify('Client created', { type: 'success' });
					redirect('/clients');
				},
			});
		// {
		// 	onSuccess: (client) => createContact(client, formData),
		// 	onError: () => notify('Error while creating client. Please try again.', { type: 'error' })
		// });
	};

	// const createContact = (client, formData) => {
	// 	const contactRequest = {
	// 		contact_name: formData.contact_name,
	// 		client_id: client.id,
	// 		preferred_contact_way: formData.preferred_contact_way,
	// 		email: formData.email,
	// 		phone: formData.phone || "",
	// 		position: formData.position,
	// 		location: formData.location,
	// 		time_zone: formData.time_zone,
	// 		is_main: formData.is_main,
	// 		project_id: 0
	// 	};
	// 	create(
	// 		'contacts',
	// 		{
	// 			data: contactRequest
	// 		},
	// 		{
	// 			onSuccess: () => {
	// 				notify('Client created', { type: 'success' });
	// 				redirect('/clients');
	// 			},
	// 			onError: () => {
	// 				notify('Error while creating contact. Please try again in contacts page.', { type: 'error' });
	// 				redirect('/clients');
	// 			}
	// 		});
	// };

	return (
		<Create actions={false} redirect="show">
			<Form onSubmit={onSubmit} warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<BusinessIcon />
						</Avatar>
						<Box ml={2} flex="1">
							<ClientForm status={StatusData} isLoading={isLoadingStatus} />
							<Divider sx={{ mb: 2, width: '100%' }} />
							{/* TODO: When the feature flags are implemented we should manage the logic using AND operator */}
							{/* <ContactForm isOnClientPage={true}/> */}
						</Box>
					</Stack>
				</CardContent>
				<Toolbar>
					<SaveButton disabled={isLoading} />
				</Toolbar>
			</Form>
		</Create>
	);
};

export default ClientCreate;
