import React from 'react';
import { TextInput, SelectInput, required } from 'react-admin';
import { Stack } from '@mui/material';


const ClientForm = ({status}) => {
	return (
		<>
			<TextInput source="name" validate={required()} fullWidth />
			<Stack direction="row" sx={{ gap: '2%' }}>
				<SelectInput
					source="status.id"
					label="Status"
					choices={status || []}
					validate={required()}
					fullWidth
				/>
				{/* <SelectInput
					source="industry"
					choices={industries}
					validate={required()}
					fullWidth
				/> */}
			</Stack>
		</>
	);
};

export default ClientForm;

