import React from 'react';
import { useListContext } from 'react-admin';

import LoadingProjectList from './LoadingProjectList';
import LoadedProjectList from './LoadedProjectList';

export const ProjectGridList = () => {
	const { isLoading } = useListContext();
	return isLoading ? <LoadingProjectList /> : <LoadedProjectList />;
};


