import React from 'react';
import {
	List,
	Pagination,
	useGetIdentity
} from 'react-admin';


import { PositionGridList }  from '../../components/positions/list/PositionGridList';
import PositionListActions from '../../components/positions/list/PositionListActions';


const PositionList = () => {
	const { identity } = useGetIdentity();
	if (!identity) return null;
	return (
		<List
			actions={<PositionListActions />}
			pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
			perPage={25}
			component="div"
		>
			<PositionGridList />
		</List>

	);
};

export default PositionList;
