import { fetchUtils } from 'react-admin';

// eslint-disable-next-line
const urlServer = `${process.env.REACT_APP_SERVER_URL}/auth/user-permissions`;
// eslint-disable-next-line
const urlAuth = `${process.env.REACT_APP_AUTH_URL}/authn/logout`;

const optionsServer = {};
optionsServer.headers = new Headers({ Accept: 'application/json' });
// eslint-disable-next-line
optionsServer.headers.set('x-api-key', process.env.REACT_APP_API_KEY);
optionsServer.credentials = 'include';

const options = {};
options.headers = new Headers({ Accept: 'application/json' });
options.credentials = 'include';

const authService = {
	getLoggedUser: async () => await fetchUtils.fetchJson(urlServer, optionsServer),
	getUserPermissions: async () => {
		const response = await authService.getLoggedUser();
		const { roles, feature_toggles: featureToggles } = await response.json;
		// eslint-disable-next-line
		const authAppName = process.env.REACT_APP_AUTH_APP;
		return {
			roles: roles[authAppName],
			featureToggles: featureToggles[authAppName],
		};
	},
	logout: async () => await fetchUtils.fetchJson(urlAuth, options),
};

export default authService;
