import * as React from 'react';
import { useState } from 'react';
import {
	useShowContext
} from 'react-admin';
import {
	Box,
	Card,
	CardContent,
	Typography,
	Tabs,
	Tab,
	Divider,
	Chip
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ClientDetailTabPanel from './ClientDetailTabPanel';
// import ClientContactsTab from './ClientContactsTab';
import ClientProjectsTab from './ClientProjectsTab';
// import NoteList from '../../notes/NoteList';
import { ClientAside } from './ClientAside';

const ClientDetailContent = () => {
	const { record: client, isLoading } = useShowContext();
	const [tabValue, setTabValue] = useState(0);
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};
	const statusColor = (client) => {
		switch (client.status.name) {
		case 'Active':
			return 'success';
		case 'Lead':
			return 'primary';
		case 'Cold':
			return 'error';
		}
	};
	if (isLoading || !client) return null;
	
	return (
		<Box mt={2} display="flex">
			<Box flex="1">
				<Card>
					<CardContent>
						<Box display="flex" mb={1} alignItems="center">
							<BusinessIcon color="disabled" sx={{ mr: 1 }} />
							<Box ml={2} flex="1">
								<Typography variant="h5">
									{client.name}
									<Chip sx={{marginLeft: '5px'}} label={client.status.name} color={statusColor(client)} variant="outlined"  size="small" />
								</Typography>
							</Box>
						</Box>
						<Tabs
							value={tabValue}
							indicatorColor="primary"
							textColor="primary"
							onChange={handleTabChange}
						>
							<Tab label= "Projects"/>
							<Tab label= "Contacts"/>
							<Tab label= "Notes"/>
						</Tabs>
						<Divider />
						<ClientDetailTabPanel value={tabValue} index={0}>
							<ClientProjectsTab projects={client.projects}/>
						</ClientDetailTabPanel>
						<ClientDetailTabPanel value={tabValue} index={1}>
							<Box
								component="img"
								sx={{ marginLeft: 35, width: 250 }}
								src={
									require('../../../assets/img/coming-soon.png')
								}
								alt="Clients app Isologo"
							/>
						</ClientDetailTabPanel>
						<ClientDetailTabPanel value={tabValue} index={2}>
							<Box
								component="img"
								sx={{ marginLeft: 35, width: 250 }}
								src={
									require('../../../assets/img/coming-soon.png')
								}
								alt="Clients app Isologo"
							/>
							{/* <ReferenceManyField
								reference={`clients/${client.id}/notes`}
								target="client_id"
							>
								<NoteList reference="client-notes"/>
							</ReferenceManyField> */}
						</ClientDetailTabPanel>
					</CardContent>
				</Card>
			</Box>
			<ClientAside link='edit' />
		</Box>
	);
}; 

export default ClientDetailContent;
