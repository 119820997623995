import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import { Create, Form, Toolbar, SaveButton, useGetList, useCreate, useNotify, useRedirect } from 'react-admin';
import { CardContent, Stack, Avatar, Box } from '@mui/material';
import PositionForm from '../../components/positions/create/PositionForm';

const PositionCreate = () => {

	const { data: clients } = useGetList('clients');
	const { data: projects } = useGetList('projects');
	const { data: positionRoles } = useGetList('position-roles');
	const { data: positionProfiles } = useGetList('position-profile');
	const clientsOptions = clients?.map(client => ({ id: client.id, name: client.name }));
	const projectsOptions = projects?.map(project => ({ id: project.id, name: project.project_name, client_id: project.client_id }));
	const positionRolesOptions = positionRoles?.map(positionRole => ({ id: positionRole.id, name: positionRole.name }));
	const positionProfilesOptions = positionProfiles?.map(positionProfile => ({ id: positionProfile.id, name: positionProfile.name}));

	const [create, { isLoading }] = useCreate();
	const notify = useNotify();
	const redirect = useRedirect();

	const { data: positionStatusData} = useGetList('position-status');
	const positionStatusOptions = positionStatusData?.map(positionStatus => ({ id: positionStatus.id, name: positionStatus.name }));

	const onSubmit = (formData) => {
		const positionRole = positionRoles?.filter(positionRole => positionRole.id === formData.position_role_id);
		if (positionRole.length !== 0) {
			const positionRequest = {
				client_id: formData.client_id,
				project_id: formData.project_id,
				standard_role: { "name": positionRole[0].name, "rank": positionRole[0].rank, "id": positionRole[0].id },
				position_role_id: formData.position_role_id,
				position_name: formData.position_name,
				person_time: formData.person_time.toString(),
				position_profile: formData.position_profile,
				position_status: formData.position_status,
				role_rank: formData.role_rank,
				inv_code: formData?.inv_code,
				position_start_date: formData?.position_start_date,
				position_end_date: formData?.position_end_date,
				project_rank: formData?.project_rank,
				position_skills: formData?.position_skills,
			};
			create(
				'positions',
				{
					data: positionRequest
				},
				{
					onSuccess: () => {
						notify('Position created', { type: 'success' });
						redirect('/positions');
					
					},
					onError: () => {
						notify('Error while creating Position.', { type: 'error' });
					}
				});
		}
	};

	return (
		<Create actions={false} redirect="show">
			<Form onSubmit={onSubmit} warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<WorkIcon />
						</Avatar>
						<Box ml={2} flex="1">
							<PositionForm 
								clientOptions={clientsOptions} 
								projectOptions={projectsOptions}
								positionRolesOptions={positionRolesOptions} 
								positionProfilesOptions={positionProfilesOptions} 
								positionStatusOptions={positionStatusOptions}
							/>
						</Box>
					</Stack>
				</CardContent>
				<Toolbar>
					<SaveButton disabled={isLoading}/>
				</Toolbar>
			</Form>
		</Create>
	);
};

export default PositionCreate;
