import * as React from 'react';
import { Admin, fetchUtils, Resource, defaultTheme } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import Layout from './templates/Layout';
import {
	ClientList,
	ClientCreate,
	ClientDetails,
	ClientEdit
} from './screens/clients';
import {
	ProjectList,
	ProjectCreate,
	ProjectDetails,
	ProjectEdit
} from './screens/projects';
import {
	ContactList,
	ContactCreate,
	ContactDetails,
	ContactEdit
} from './screens/contacts';
import {
	PositionList,
	PositionCreate,
	PositionEdit,
	PositionDetails
} from './screens/positions';
import {
	Login
} from './screens/login';
import authProvider from './auth/authProvider';

function App () {
	const httpClient = (url, options = {}) => {
		if (!options.headers) {
			options.headers = new Headers({ Accept: 'application/json' });
		}
		// eslint-disable-next-line
		options.headers.set('x-api-key', process.env.REACT_APP_API_KEY);
		return fetchUtils.fetchJson(url, options);
	};
	// eslint-disable-next-line
	const dataProvider = jsonServerProvider(process.env.REACT_APP_SERVER_URL, httpClient);
	return (
		<Admin
			dataProvider={dataProvider}
			layout={Layout}
			loginPage={Login}
			authProvider={authProvider}
			theme={{
				...defaultTheme,
				palette: {
					background: {
						default: '#fafafb'
					}
				}
			}}
			requireAuth
		>
			<Resource
				name="login"
			/>
			<Resource
				name="clients"
				list={ClientList}
				show={ClientDetails}
				create={ClientCreate}
				edit={ClientEdit}
			/>
			<Resource
				name="projects"
				list={ProjectList}
				show={ProjectDetails}
				create={ProjectCreate}
				edit={ProjectEdit}
			/>
			<Resource
				name="positions"
				list={PositionList}
				show={PositionDetails}
				create={PositionCreate}
				edit={PositionEdit}
			/>
			<Resource
				name="contacts"
				list={ContactList}
				create={ContactCreate}
				edit={ContactEdit}
				show={ContactDetails}
			/>
		</Admin>
	);
}

export default App;
