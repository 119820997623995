import React from 'react';
import {
	useRecordContext,
	EditButton,
	ShowButton,
	DateField
} from 'react-admin';
import { Box, Typography, Divider } from '@mui/material';

const PositionAside = ({ link }) => {
	const record = useRecordContext();
	if (!record) return null;

	return (
		<Box ml={4} width={250} minWidth={250}>
			<Box textAlign="center" mb={2}>
				{link === 'edit' ? (
					<EditButton label="Edit Position" />
				) : (
					<ShowButton label="Show Position" />
				)}
			</Box>
			<Typography variant="subtitle2">Position info</Typography>
			<Divider />
			<Box mt={2}>
				<Typography variant="body2">
					{'Profile: '}
					<Typography
						variant="body2"
						color="textSecondary"
						component="span"
					>
						{record.position_profile}
					</Typography>
					<br />
					{'Status: '}
					<Typography
						variant="body2"
						color="textSecondary"
						component="span"
					>
						{record.position_status}
					</Typography>
					<br />
					{'Project Rank: '}
					<Typography
						variant="body2"
						color="textSecondary"
						component="span"
					>
						{record.standard_role.rank}
					</Typography>
					<br />
					{'Person time: '}
					<Typography
						variant="body2"
						color="textSecondary"
						component="span"
					>
						{record.person_time}%
					</Typography>
					<br />
					{'Start date: '}
					{
						record.position_start_date ? 
							(
								<DateField
									source="position_start_date"
									color="textSecondary"
								/>
							)
							:
							'-'
					}
					<br />
					{'End date: '}
					{
						record.position_end_date ? 
							(
								<DateField
									source="position_end_date"
									color="textSecondary"
								/>
							)
							:
							'-'
					}
				</Typography>
			</Box>
			<Typography variant="subtitle2" mt={3}>Background</Typography>
			<Divider />
			<Box mt={2}>
				<Typography variant="body2" color="textSecondary">
					{'Added on '}
					<DateField
						source="created_date"
						options={{ year: 'numeric', month: 'long', day: 'numeric' }}
						color="textSecondary"
					/>
				</Typography>
			</Box>
		</Box>
	);
};

export default PositionAside;
