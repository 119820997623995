import React from 'react';
import { Edit, Form, Toolbar, useGetList, useUpdate, useNotify, useRedirect, useGetRecordId } from 'react-admin';
import { Box, CardContent, Stack, Avatar } from '@mui/material';

import BusinessIcon from '@mui/icons-material/Business';
import ClientForm from '../../components/clients/create/ClientForm';
import { ClientAside } from '../../components/clients/details/ClientAside';

const ClientEdit = () => {

	const { data: StatusData} = useGetList('client-status');
	const recordId = useGetRecordId();
	const [update] = useUpdate();
	const notify = useNotify();  
	const redirect = useRedirect();

	const onSubmit = (formData) => {
		update(
			'clients',
			{
				id: recordId, data: { id: recordId, name: formData.name, status: formData.status },
			},
			{
				onSuccess: () => {
					notify('Updated created', { type: 'success' });
					redirect('/clients');
				},
			});
	};

	return (
		<Edit
			aside={<ClientAside link="show" />}
			actions={false}
			redirect="show"
		>
			<Form onSubmit={onSubmit} warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<BusinessIcon />
						</Avatar>
						<Box ml={2} flex="1" maxWidth={796}>
							<ClientForm status={StatusData} />
						</Box>
					</Stack>
				</CardContent>
				<Toolbar />
			</Form>
		</Edit>
	);
};

export default ClientEdit;
