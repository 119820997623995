import React from 'react';
import { Box, Paper } from '@mui/material';

const times = (nbChildren, fn) =>
	Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingClientGridList = () => (
	<Box display="flex" flexWrap="wrap" width={1008} gap={1}>
		{times(15, key => (
			<Paper
				sx={{
					height: 300,
					width: 220,
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: 'grey[200]'
				}}
				key={key}
			/>
		))}
	</Box>
);

export default LoadingClientGridList;
