import React from 'react';
import { useListContext } from 'react-admin';

import LoadingPositionList from './LoadingPositionList';
import LoadedPositionList from './LoadedPositionList';

export const PositionGridList = () => {
	const { isLoading } = useListContext();
	return isLoading ? <LoadingPositionList /> : <LoadedPositionList />;
};
