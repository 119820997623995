import React from 'react';
import {
	useRecordContext,
	EditButton,
	ShowButton
} from 'react-admin';
import { Box, Typography, Divider } from  '@mui/material';

export const ContactAside = ({ link }) => {
	const record = useRecordContext();
	if (!record) return null;
	return (
		<Box ml={4} width={250} minWidth={250}>
			<Box textAlign="center" mb={2}>
				{link === 'edit' ? (
					<EditButton label="Edit Contact" />
				) : (
					<ShowButton label="Show Contact" />
				)}
			</Box>
			<Box mt={2}>
				<Typography variant="subtitle2">Contact info</Typography>
				<Divider />
				<Typography variant="body2" mt={2}>
                    Phone: {record.phone}
					<br />
                    Email: {record.email}
					<br />
                    Location: {record.location}
					<br />
                    Time_Zone: {record.time_zone}
				</Typography>
			</Box>
		</Box>
	);
};
