import React from 'react';

import { useListContext, RecordContextProvider } from 'react-admin';
import {
	Box,
	List,
	Divider
} from '@mui/material';


import ProjectListItem from './ProjectListItem';


const LoadedProjectGridList = () => {
	const { data: projects, isLoading } = useListContext();

	if (isLoading) return null;

	return (
		<Box>
			<List sx={{background:"white", marginTop: '5px'}}>
				{projects?.map((project, index) => (
					<div key={index}>
						<RecordContextProvider value={project}>
							<ProjectListItem
								project={project}
							/>
							<Divider variant='middle'/>
						</RecordContextProvider>
					</div>
				))}
			</List>
		</Box>

	);
};

export default LoadedProjectGridList;
