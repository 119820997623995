import React from 'react';
import { Edit, Form, Toolbar, useGetList } from 'react-admin';
import { Box, CardContent, Stack, Avatar } from '@mui/material';

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ProjectForm from '../../components/projects/create/ProjectForm';
import ProjectAside from '../../components/projects/details/ProjectAside';

const ProjectEdit = () => {

	const { data: clients } = useGetList('clients');
	const { data: projectStatusData} = useGetList('project-status');

	const clientsOptions = clients?.map(client => ({ id: client.id, name: client.name }));
	const projectStatusOptions = projectStatusData?.map(projectStatus => ({ id: projectStatus.id, name: projectStatus.name }));

	return (
		<Edit
			aside={<ProjectAside link="show" />}
			actions={false}
			redirect="show"
		>
			<Form warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<CreateNewFolderIcon />
						</Avatar>
						<Box ml={2} flex="1" maxWidth={796}>
							<ProjectForm 
								clientOptions={clientsOptions} 
								projectStatusOptions={projectStatusOptions}
							/>
						</Box>
					</Stack>
				</CardContent>
				<Toolbar />
			</Form>
		</Edit>
	);
};

export default ProjectEdit;
