import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
	name: 'session',
	initialState: {
		user: ''
	},
	reducers: {
		login: (state, action) => {
			state.user = action.payload;
		}
	}
});

export const { login } = sessionSlice.actions;

export default sessionSlice.reducer;
