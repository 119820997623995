import React from 'react';

import { useListContext, RecordContextProvider, useGetList } from 'react-admin';
import {
	Box,
	List,
	Divider
} from '@mui/material';

import ContactListItem from './ContactListItem';


const LoadedContactGridList = () => {
	const { data: contacts, isLoading } = useListContext();
	const { data: clients } = useGetList('clients');

	if (isLoading) return null;

	return (
		<Box>
			<List sx={{background:"white", marginTop: '5px'}}>
				{contacts?.map((contact, index) => (
					<RecordContextProvider key={index} value={contact}>
						<ContactListItem
							contact={contact}
							clients={clients}
						/>
						<Divider variant='middle'/>
					</RecordContextProvider>
				))}
			</List>
		</Box>
		
	);
};

export default LoadedContactGridList;
