import React from 'react';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Create, Form, Toolbar, useCreate, useNotify, useRedirect, useGetList, SaveButton } from 'react-admin';
import { CardContent, Divider, Stack, Avatar, Box } from '@mui/material';

import ProjectForm from '../../components/projects/create/ProjectForm';

const ProjectCreate = () => {
	const [create, { isLoading }] = useCreate();
	const { data: clients } = useGetList('clients');
	const clientsOptions = clients?.map(client => ({ id: client.id, name: client.name }));
	const { data: projectStatusData} = useGetList('project-status');
	const projectStatusOptions = projectStatusData?.map(projectStatus => ({ id: projectStatus.id, name: projectStatus.name }));
	const notify = useNotify();
	const redirect = useRedirect();


	const onSubmit = (formData) => {
		const projectRequest = {
			client_id: formData.client_id,
			project_name: formData.project_name,
			inv_code: formData.inv_code,
			status: formData.status.id,
			project_contact: formData.project_contact,
			account_manager: formData.account_manager,
			sales_person: formData.sales_person,
			tech_stack: formData.tech_stack
		};
		create(
			'projects',
			{
				data: projectRequest
			},
			{
				onSuccess: () => {
					notify('Project created', { type: 'success' });
					redirect('/projects');
				},
				onError: () => {
					notify('Error while creating Project. Please try again in Projects page.', { type: 'error' });
				}});
	};



	return (
		<Create actions={false} redirect="show">
			<Form onSubmit={onSubmit} warnWhenUnsavedChanges>

				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<CreateNewFolderIcon />
						</Avatar>
						<Box ml={2} flex="1">
							<ProjectForm
								clientOptions={clientsOptions} /*contactOptions={contactsOptions}*/
								projectStatusOptions={projectStatusOptions}
							/>
							<Divider sx={{ mb: 2, width: '100%' }} />
						</Box>
					</Stack>
				</CardContent>
				<Toolbar>
					<SaveButton disabled={isLoading} />
				</Toolbar>
			</Form>
		</Create>
	);
};

export default ProjectCreate;
