import React from 'react';
import {
	useRecordContext,
	EditButton,
	ShowButton
} from 'react-admin';
import { Box } from '@mui/material';

const ProjectAside = ({ link }) => {
	const record = useRecordContext();
	if (!record) return null;
	return (
		<Box ml={4} width={250} minWidth={250}>
			<Box textAlign="center" mb={2}>
				{link === 'edit' ? (
					<EditButton label="Edit Project" />
				) : (
					<ShowButton label="Show Project" />
				)}
			</Box>
		</Box>
	);
};

export default ProjectAside;
