import React from "react";
import {
	Box,
	Typography,
	ListItemButton,
	ListItemText,
	Chip,
	TableCell,
	TableRow,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	Paper
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DateField, DeleteWithConfirmButton } from "react-admin";


const PositionListItem = ({ position, specificProject }) => {
	const statusColor = (position) => {
		const status = position.position_status.toLowerCase();
		switch(status) {
		case 'filled':
		case 'urgent':
			return 'error';
		case 'open':
			return 'primary';
		case 'vacated':
			return 'warning';
		case 'needs review':
		case 'low priority':
		case 'waiting onboarding':
			return 'secondary';
		case 'coming soon':
		case 'proposal':
			return 'warning';
		case 'waiting for answer':
			return 'info';
		case 'internal open':
			return 'success';
		}
	};

	//TODO: get client name
	const getRoleSkills = (roleskills) => {
		return (
			<>
				{roleskills?.map((roleSkill, index) => (
					<div key={index}>{roleSkill}</div>
				))}
			</>
		);
	};
	return (
		<li style={{display: 'flex', flexDirection: 'row'}}>
			<ListItemButton
				key={position.id}
				component={RouterLink}
				to={`/positions/${position.id}/show`}
			>
				<Box
					display='flex'
					flexDirection='column'
					gap={2}
					width='100%'
				>
					<ListItemText
						primary={
							<>
								<Typography sx={{display: 'contents'}}>
									{position.poition_name}
								</Typography>
								<Typography variant="caption" color="textSecondary" sx={{marginLeft: '5px'}}>
									{position.client_name}
								</Typography>
							</>
						}
					/>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }}>
							<TableHead>
								<TableRow>
									{!specificProject && <TableCell >Project</TableCell>}
									<TableCell>Name</TableCell>
									<TableCell>Standard role</TableCell>
									<TableCell>Start date</TableCell>
									<TableCell>End date</TableCell>
									<TableCell>Time</TableCell>
									<TableCell>Profile</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Position skills</TableCell>
									<TableCell>Rank</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									{!specificProject &&  <TableCell data-testid='project-name-column-value' >{position.project_name}</TableCell>}
									<TableCell>{position.position_name}</TableCell>
									<TableCell>{position.standard_role.name}</TableCell>
									<TableCell data-testid="position-start-date">
										<DateField source="position_start_date"/>
									</TableCell>
									<TableCell data-testid="position-end-date">
										<DateField source="position_end_date"/>
									</TableCell>
									<TableCell>{position.person_time}</TableCell>
									<TableCell>{position.position_profile}</TableCell>
									<TableCell>
										<Chip label={position.position_status} color={statusColor(position)} variant="outlined"  size="small" />
									</TableCell>
									<TableCell>{getRoleSkills(position.position_skills)}</TableCell>
									<TableCell>{position.standard_role.rank}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<Typography variant="caption" color="textSecondary">
								Created date:
						<DateField variant="caption"
							source="created_date"
						/>
					</Typography>
				</Box>
			</ListItemButton>
			{
				!specificProject &&
					<DeleteWithConfirmButton
						confirmTitle={`Delete position: ${position.person_name}?`}
						confirmContent="You will not be able to recover this record. Are you sure?"
						sx={{alignSelf: 'center', mx: 2}}
					/>
			}
		</li>

	);
};

export default PositionListItem;
