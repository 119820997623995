import React, { useState } from 'react';
import {
	useRecordContext,
	useListContext,
	useCreate,
	useNotify,
	useGetIdentity
} from 'react-admin';
import { Box, TextField, Button } from '@mui/material';

export const NoteCreateForm = ({
	reference,
}) => {
	const record = useRecordContext();
	const { refetch } = useListContext();
	const [text, setText] = useState('');
	const [author, setAuthor] = useState('');
	const [create, { isLoading }] = useCreate();
	const notify = useNotify();
	const { identity } = useGetIdentity();
	if (!record || !identity) return null;

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = {
			[foreignKeyMapping[reference]]: record.id,
			author: author,
			note: text,
			creation_date: new Date().toISOString()
		};
		create(
			reference,
			{ data },
			{
				onSuccess: () => {
					setText('');
					notify('Note added successfully');
					refetch();
				},
			}
		);
		return false;
	};

	return (
		<Box mt={4} mb={1}>
			<form onSubmit={handleSubmit}>
				<TextField
					label="Add a note"
					variant="filled"
					size="small"
					fullWidth
					multiline
					value={text}
					onChange={(event) =>
						setText(event.target.value)
					}
					rows={3}
				/>
				<Box display="flex" justifyContent="space-between" mt={1} alignItems='center'>
					<span>
						{text ? (
							<>
								<TextField
									label='Author'
									size="small"
									value={author}
									onChange={(
										event
									) => {
										setAuthor(event.target.value);
									}}
									required
								/>
							</>
						) : null}
					</span>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={!text || isLoading}
						sx={{
							height: '36.5px'
						}}
					>
                        Add this note
					</Button>
				</Box>
			</form>
		</Box>
	);
};

const foreignKeyMapping = {
	'client-notes': 'client_id',
	'contact-notes': 'contact_id',
	'project-notes': 'project_id',
	'role-notes': 'role_id'
};
