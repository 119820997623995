import React from 'react';
import {
	useRecordContext,
	EditButton,
	ShowButton
} from 'react-admin';
import { Box, Typography, Divider } from '@mui/material';

export const ClientAside = ({ link }) => {
	const record = useRecordContext();
	if (!record) return null;
	return (
		<Box ml={4} width={250} minWidth={250}>
			<Box textAlign="center" mb={2}>
				{link === 'edit' ? (
					<EditButton label="Edit Client" />
				) : (
					<ShowButton label="Show Client" />
				)}
			</Box>
			<Typography variant="subtitle2">Client info</Typography>
			<Divider />
			<Box mt={2}>
				<Typography variant="body2">
                    Status: {record.status.name}
					<br />
					{/* TODO: Uncomment when information is retrieved from BFF*/}
					{/* Industry: {record.industry} */}
				</Typography>
			</Box>

		</Box>
	);
};
