import React, { useState } from 'react';
import {
	TextField,
	DateField,
	useDelete,
	useUpdate,
	useNotify,
	useListContext
} from 'react-admin';
import {
	Box,
	Typography,
	Tooltip,
	IconButton,
	FilledInput,
	Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';


export const Note = ({
	note,
	reference,
}) => {
	const [isHover, setHover] = useState(false);
	const [isEditing, setEditing] = useState(false);
	const [noteText, setNoteText] = useState(note.note);
	const { refetch } = useListContext();
	
	const notify = useNotify();

	const [update, { isLoading }] = useUpdate();

	const [deleteNote] = useDelete(
		reference,
		{
			id: note.id,
			previousData: note
		},
		{
			mutationMode: 'undoable',
			onSuccess: () => {
				notify('Note deleted', { type: 'info', undoable: true });
				refetch();
			},
		}
	);

	const handleDelete = () => {
		deleteNote();
	};

	const handleEnterEditMode = () => {
		setEditing(true);
	};

	const handleCancelEdit = () => {
		setEditing(false);
		setNoteText(note.note);
		setHover(false);
	};

	const handleTextChange = (event) => {
		setNoteText(event.target.value);
	};

	const handleNoteUpdate = (event) => {
		event.preventDefault();
		update(
			reference,
			{
				id: note.id,
				data: {
					...note,
					note: noteText,
				},
				previousData: note
			},
			{
				onSuccess: () => {
					setEditing(false);
					setNoteText(noteText);
					setHover(false);
					refetch();
				},
			}
		);
	};

	return (
		<Box
			mb={2}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Box mb={1} color="text.secondary">
				<TextField source="author" record={note} variant="body1" sx={{ color: 'rgb(25, 118, 210)' }} />
				<Typography component="span" variant="body1">
					{' '}added a note on{' '}
				</Typography>
				<DateField
					source="creation_date"
					record={note}
					variant="body1"
					showTime
					locales="en"
					options={{
						dateStyle: 'full',
						timeStyle: 'short',
					}}
				/>{' '}
			</Box>
			{isEditing ? (
				<form onSubmit={handleNoteUpdate}>
					<FilledInput
						value={noteText}
						onChange={handleTextChange}
						fullWidth
						multiline
						sx={{
							paddingTop: '16px',
							paddingLeft: '14px',
							paddingRight: '60px',
							paddingBottom: '14px',
							lineHeight: 1.3,
						}}
						autoFocus
					/>
					<Box display="flex" justifyContent="flex-end" mt={1}>
						<Button
							sx={{ mr: 1 }}
							onClick={handleCancelEdit}
							color="primary"
						>
                            Cancel
						</Button>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={isLoading}
						>
                            Update Note
						</Button>
					</Box>
				</form>
			) : (
				<Box
					sx={{
						bgcolor: '#edf3f0',
						padding: '0 1em',
						borderRadius: '10px',
						display: 'flex',
						alignItems: 'stretch',
						marginBottom: 1,
					}}
				>
					<Box flex={1}>
						{note.note
							.split('\n')
							.map((paragraph, index) => (
								<Box
									component="p"
									fontFamily="fontFamily"
									fontSize="body1.fontSize"
									lineHeight={1.3}
									marginBottom={2.4}
									key={index}
								>
									{paragraph}
								</Box>
							))}
					</Box>
					<Box
						sx={{
							marginLeft: 2,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
							visibility: isHover ? 'visible' : 'hidden',
						}}
					>
						<Tooltip title="Edit note">
							<IconButton
								size="small"
								onClick={handleEnterEditMode}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete note">
							<IconButton size="small" onClick={handleDelete}>
								<TrashIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			)}
		</Box>
	);
};
