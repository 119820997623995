import React from 'react';
import {
	TopToolbar,
	CreateButton
} from 'react-admin';

const ProjectListActions = () => {
	return (
		<TopToolbar>
			<CreateButton
				variant="contained"
				label="New Project"
				sx={{ marginLeft: 2 }}
			/>
		</TopToolbar>
	);
};

export default ProjectListActions;
