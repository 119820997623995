import React from 'react';
import {
	List,
	Pagination,
	useGetIdentity
} from 'react-admin';


import { ProjectGridList }  from '../../components/projects/list/ProjectGridList';
import ProjectListActions from '../../components/projects/list/ProjectListActions';


const ProjectList = () => {
	const { identity } = useGetIdentity();
	if (!identity) return null;
	return (
		<List
			actions={<ProjectListActions />}
			pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
			perPage={25}
			component="div"
		>
			<ProjectGridList />
		</List>
		
	);
};

export default ProjectList;
