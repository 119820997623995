import React from 'react';

import { useListContext, RecordContextProvider } from 'react-admin';
import {
	Box,
	List,
	Divider
} from '@mui/material';


import PositionListItem from './PositionListItem';


const LoadedPositionGridList = ({specificProject = false, projectPositions = null}) => {
	let positions = projectPositions;
	if(!positions) {
		const { data, isLoading } = useListContext();
		positions = data;
		if (isLoading) return null;
	}
	return (
		<Box>
			<List sx={{background:"white", marginTop: '5px'}}>
				{positions?.map((position, index) => (
					<RecordContextProvider key={index} value={position}>
						<PositionListItem
							position={position}
							specificProject={specificProject}
						/>
						<Divider variant='middle'/>
					</RecordContextProvider>
				))}
			</List>
		</Box>

	);
};

export default LoadedPositionGridList;
