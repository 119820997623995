import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function BackgroundSpinner({ open }) {
	const style = { color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 };

	return (
		<Backdrop sx={style} aria-hidden={!open} open={open} >
			<CircularProgress aria-describedby="spinner" color="inherit" />
		</Backdrop>
	);
}
