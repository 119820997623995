import React from 'react';
import { useListContext } from 'react-admin';

import LoadingClientGridList from './LoadingClientGridList';
import LoadedClientGridList from './LoadedClientGridList';

export const ClientCardsGrid = () => {
	const { isLoading } = useListContext();
	return isLoading ? <LoadingClientGridList /> : <LoadedClientGridList />;
};
