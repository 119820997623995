import React from "react";
import {
	Typography,
	ListItemButton,
	ListItemText,
	ListItemSecondaryAction,
	Chip
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DeleteWithConfirmButton } from "react-admin";
import PositionSkillsList from "../../positions/details/PositionSkillsList";


const ProjectListItem = ({ project, isOnSpecificClientPage = false }) => {

	const statusColor = (project) => {
		switch(project.status) {
		case 'Active':
			return 'success';
		case 'Lead':
			return 'primary';
		case 'Cold':
			return 'error';
		}
	};

	/*const getContactName = () => {
		const contact = contacts.filter(contact => contact.id == project.project_contact);
		return contact[0]?.contact_name || '';
	};*/



	return (
		<li style={{display: 'flex', flexDirection: 'row'}} role="table">
			<ListItemButton
				key={project.id}
				component={RouterLink}
				to={`/projects/${project.id}/show`}
			>
				<ListItemText
					primary={
						<>
							<Typography sx={{display: 'contents'}}>
								{project.project_name}
							</Typography>
							{
								isOnSpecificClientPage ?
									(
										<Typography variant="caption" color="textSecondary" sx={{marginLeft: '5px'}}>
											{project.inv_code}
										</Typography>
									)
									:
									(
										<Typography variant="caption" color="textSecondary" sx={{marginLeft: '5px'}}>
											{project.client_name}
										</Typography>
									)
							}
						</>
					}
					secondary={
						<>
							{!isOnSpecificClientPage && (<>Invoice code: {project.inv_code} <br /> </>)}
							{/*<span/> Project Contact: {getContactName()} <br />*/}
							<span/> <Typography> Account Manager: {project.account_manager} </Typography> 
							<span/> <Typography> Sales Person: {project.sales_person} </Typography>
							<span/>  <Typography> Tech Stack: 	<PositionSkillsList skills={project.tech_stack} /> </Typography>
						</>
					}
				/>
				<ListItemSecondaryAction>
					<Chip label={project.status.name} color={statusColor(project)} variant="outlined"  size="small" />
				</ListItemSecondaryAction>
			</ListItemButton>
			{!isOnSpecificClientPage && (
				<DeleteWithConfirmButton
					confirmTitle={`Delete project: ${project.project_name}?`}
					confirmContent="You will not be able to recover this record. Are you sure?"
					sx={{alignSelf: 'center'}}
				/>
			)}
		</li>

	);
};

export default ProjectListItem;
