import React from 'react';
import {
	ShowBase
} from 'react-admin';
import PositionDetailContent from '../../components/positions/details/PositionDetailContent';


const ProjectDetails = () => {

	return (
		<ShowBase>
			<PositionDetailContent />
		</ShowBase>
	);
};

export default ProjectDetails;
