import * as React from 'react';
import {
	useListContext
} from 'react-admin';
import {
	List
} from '@mui/material';
import { Note } from './Note';
import { NoteCreateForm } from './NoteCreateForm';

const NoteList = ({reference}) => {
	const { data: notes, isLoading } = useListContext();
	if (isLoading) return null;

	return (
		<>
			<NoteCreateForm showStatus={false} reference={reference} />
			<List>
				{notes?.map((note, index) => (
					<Note
						note={note}
						showStatus={false}
						key={index}
						reference={reference}
					/>
				))}
			</List>
		</>
	);
};

export default NoteList;
