import * as React from 'react';
import {
	ShowBase
} from 'react-admin';
import ClientDetailContent from '../../components/clients/details/ClientDetailContent';

const ClientDetails = () => (
	<ShowBase>
		<ClientDetailContent />
	</ShowBase>
);

export default ClientDetails;
 