import React from "react";
import {
	Chip,
	ListItemButton,
	ListItemText,
	ListItemSecondaryAction,
	Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DeleteWithConfirmButton } from "react-admin";

const ContactListItem = ({ contact, isOnSpecificClientPage = false, clients }) => {

	const getClientName = () => {
		const client = clients?.filter(client => client.id == contact.client_id);
		return client ? (client[0]?.name || '') : '';
	};

	return (
		<li style={{display: 'flex', flexDirection: 'row'}}>
			<ListItemButton component={RouterLink} to={`/contacts/${contact.id}/show`}>
				<ListItemText
					primary={
						<>
							<Typography sx={{ display: "contents" }}>
								{contact.contact_name}
							</Typography>
							<Typography
								variant="caption"
								color="textSecondary"
								sx={{ marginLeft: "5px" }}
							>
								{contact.position} {!isOnSpecificClientPage && `at ${getClientName()}`}
							</Typography>
						</>
					}
					secondary={`${contact.email} |
				 ${contact.phone} | ${contact.location} | ${contact.time_zone}`}
				/>
				{contact.is_main && (
					<ListItemSecondaryAction>
						<Chip
							label="Main Contact"
							color="success"
							variant="outlined"
							size="small"
						/>

					</ListItemSecondaryAction>
				)}
			</ListItemButton>
			<DeleteWithConfirmButton
				confirmTitle={`Delete contact: ${contact.contact_name}?`}
				confirmContent="You will not be able to recover this record. Are you sure?"
				sx={{alignSelf: 'center'}}
			/>
		</li>
		
	);
};

export default ContactListItem;
