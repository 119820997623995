import React from 'react';
import { CssBaseline, Container } from '@mui/material';

import Navbar from '../components/Navbar';
import '../styles/Layout.css';

const Layout = (props) => {
	const { children } = props;
	return (
		<>
			<CssBaseline />
			<Navbar />
			<Container sx={{ maxWidth: { xl: 1280 } }}>
				<main id="main-content">
					{children}
				</main>
			</Container>
		</>
	);
};

export default Layout;
