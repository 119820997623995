import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import { Create, Form, Toolbar, SaveButton, useGetList, useCreate, useNotify, useRedirect } from 'react-admin';
import { CardContent, Stack, Avatar, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ContactForm from '../../components/contacts/create/ContactForm';

const ContactCreate = () => {

	const { data: clients } = useGetList('clients');
	const { data: projects } = useGetList('projects');
	const clientsOptions = clients?.map(client => ({ id: client.id, name: client.name }));
	const projectsOptions = projects?.map(project => ({ id: project.id, name: project.project_name, client_id: project.client_id }));
	const [create, { isLoading }] = useCreate();
	const notify = useNotify();
	const redirect = useRedirect();
	const location = useLocation();
	const { isFromSpecificClientPage, record } = location.state;

	const onSubmit = (formData) => {

		const contactRequest = {
			contact_name: formData.contact_name,
			client_id: formData.client_id,
			project_id: formData.project_id,
			preferred_contact_way: formData.preferred_contact_way,
			email: formData.email,
			phone: formData.phone || "",
			position: formData.position,
			location: formData.location,
			time_zone: formData.time_zone,
			is_main: formData.is_main
		};
		create(
			'contacts',
			{
				data: contactRequest
			},
			{
				onSuccess: () => {
					notify('Contact created', { type: 'success' });
					if (isFromSpecificClientPage)
						redirect(`/clients/${record.client_id}/show`);
					else
						redirect('/contacts');
					
				},
				onError: () => {
					notify('Error while creating contact.', { type: 'error' });
				}
			});
	};

	return (
		<Create actions={false} redirect="show">
			<Form onSubmit={onSubmit} warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<BusinessIcon />
						</Avatar>
						<Box ml={2} flex="1">
							<ContactForm clientOptions={clientsOptions} projectOptions={projectsOptions} isFromSpecificClientPage={isFromSpecificClientPage} />
						</Box>
					</Stack>
				</CardContent>
				<Toolbar>
					<SaveButton disabled={isLoading}/>
				</Toolbar>
			</Form>
		</Create>
	);
};

export default ContactCreate;
