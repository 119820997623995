import React from 'react';
import {
	Box,
	List
} from '@mui/material';

import ProjectListItem from '../../projects/list/ProjectListItem';
import CreateRelatedProjectButton from './CreateRelatedProjectButton';

const ClientProjectsTab = ({projects}) => {
	return (
		<Box>
			<List>
				{projects.map((project, index) => (
					<ProjectListItem
						project={project}
						isOnSpecificClientPage={true}
						key={index}
					/>
				))}
			</List>
			<Box textAlign="center" mt={1}>
				<CreateRelatedProjectButton />
			</Box>
		</Box>
	);
};

export default ClientProjectsTab;
