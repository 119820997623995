import React from 'react';
import {
	useRecordContext,
	ReferenceField,
	TextField,
} from 'react-admin';
import WorkIcon from '@mui/icons-material/Work';
import {
	Box,
	Typography,
	Card,
	CardContent,
	Chip,
	Divider
} from '@mui/material';
import PositionAside from './PositionAside';
import RoleSkillsList from './PositionSkillsList';

const RoleDetailContent = () => {
	const record = useRecordContext();
	if (!record) return null;
	const statusColor = (position) => {
		const status = position.position_status.toLowerCase();
		switch(status) {
		case 'filled':
		case 'urgent':
			return 'error';
		case 'open':
			return 'primary';
		case 'vacated':
			return 'warning';
		case 'needs review':
		case 'low priority':
		case 'waiting onboarding':
			return 'secondary';
		case 'coming soon':
		case 'proposal':
			return 'warning';
		case 'waiting for answer':
			return 'info';
		case 'internal open':
			return 'success';
		}
	};


	return (
		<Box mt={2} display="flex">
			<Box flex="1">
				<Card>
					<CardContent>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="start"
						>
							<Box
								width={80}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<WorkIcon color="disabled"/>
							</Box>
							<Box ml={2} flex="1">
								<Typography variant="h5">
									{record.position_name}
									<Chip sx={{marginLeft: '5px'}} label={record.position_status} color={statusColor(record)} variant="outlined"  size="small" />
								</Typography>
								<Typography variant="body2">
									<ReferenceField
										source='project_id'
										reference="projects"
										link="show"
									>
										<TextField
											variant='subtitle2'
											sx={{textAlign: 'center'}}
											source="project_name"
											align="center"
										/>
									</ReferenceField>
									{' at '}
									<ReferenceField
										source='client_id'
										reference="clients"
										link="show"
									>
										<TextField
											variant='subtitle2'
											sx={{textAlign: 'center'}}
											source="name"
											align="center"
										/>
									</ReferenceField>
								</Typography>
								{
									record.person_name &&
									(
										<Box display="flex" mt={2}>
											<Box display="flex" mr={5} flexDirection="column">
												<Typography color="textSecondary" variant="body2">
													Person
												</Typography>
												<Typography variant="subtitle1">
													{record.person_name}
												</Typography>
											</Box>
										</Box>
									)
								}
								{record?.standard_role && 
								(
									<Box mt={2} mb={2}>
										<Box
											display="flex"
											mr={5}
											flexDirection="column"
											minHeight={48}
										>
											<Typography color="textSecondary" variant="body2">
                                           Standard role
											</Typography>
											<Typography variant="subtitle1">
												{record.standard_role.name}
											</Typography>
										</Box>
									</Box>
								)}
								{record?.position_skills && 
								(
									<Box mt={2} mb={2}>
										<Box
											display="flex"
											mr={5}
											flexDirection="column"
											minHeight={48}
										>
											<Typography color="textSecondary" variant="body2">
                                            Position skills
											</Typography>
											<RoleSkillsList skills={record.position_skills} />
										</Box>
									</Box>
								)}
								<Divider />
								{/* <Box mt={2}>
									<ReferenceManyField
										reference={`roles/${record.id}/notes`}
										target="role_id"
									>
										<NoteList reference="role-notes"/>
									</ReferenceManyField>
								</Box>		 */}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
			<PositionAside link='edit' />
		</Box>
	);
};

export default RoleDetailContent;
