import React, { useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import { Paper, Typography, Link as MuiLink, Box, Avatar } from '@mui/material';
import ContactsIcon from '@mui/icons-material/AccountCircle';
import DealIcon from '@mui/icons-material/MonetizationOn';
import { DeleteWithConfirmButton, useCreatePath, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

import '../../../styles/ClientCard.css';

const ClientCard = (props) => {
	const [elevation, setElevation] = useState(1);
	const createPath = useCreatePath();
	const client = useRecordContext(props);
	if (!client) return null;

	return (
		<Paper
			sx={{
				height: 300,
				width: 220,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '1em'
			}}
			role='table'
			elevation={elevation}
		>
			<Box display="flex" flexDirection="row" justifyContent="end">
				<DeleteWithConfirmButton
					className='delete-client-button'
					label=''
					alignIcon='left'
					confirmTitle={`Delete client: ${client.name}?`}
					confirmContent="You will not be able to recover this record. Are you sure?"
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						margin: 0,
						minWidth: 0,
						alignSelf: 'end',
						'& .RaButton-smallIcon': {
							marginRight: 0
						}
					}}/>
			</Box>
			<MuiLink
				component={Link}
				to={createPath({
					resource: 'clients',
					id: client.id,
					type: 'show'
				})}
				underline="none"
				onMouseEnter={() => setElevation(3)}
				onMouseLeave={() => setElevation(1)}
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" marginTop={1}>
						<Avatar sx={{ alignSelf: 'center','& img': { objectFit: 'contain' }, }}>
							<BusinessIcon sx={{ alignSelf: 'center' }} />
						</Avatar>
                        
						<Typography variant="subtitle1" sx={{ fontWeight: 'bold'}}>
							{client.name}
						</Typography>
						<Typography variant="subtitle2">
							{client.industry}
						</Typography>
						<Typography variant="caption" color="textSecondary" sx={{fontSize: '0.8125rem'}}>
							{client.status.name}
						</Typography>
					</Box>
				</Box>
				<Box display="flex" justifyContent="space-around" width="100%">
					<Box display="flex" alignItems="center">
						<ContactsIcon color="disabled" sx={{ mr: 1 }} />
						<div>
							<Typography variant="subtitle2" sx={{ mb: -1 }}>
								{client.total_contacts}
							</Typography>
							<Typography variant="caption" color="textSecondary">
								{client.total_contacts > 1
									? 'contacts'
									: 'contact'}
							</Typography>
						</div>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<DealIcon color="disabled" sx={{ mr: 1 }} />
						<div>
							<Typography variant="subtitle2" sx={{ mb: -1 }}>
								{client.total_projects}
							</Typography>
							<Typography variant="caption" color="textSecondary">
								{client.total_projects > 1 ? 'projects' : 'project'}
							</Typography>
						</div>
					</Box>
				</Box>
			</MuiLink>
			<Box>
				{client.last_note?.note && (<>
					<Typography
						variant="caption"
						color="textSecondary"
						sx={{
							mb: 1,
							fontWeight: 'bold'
						}}>
                            Last Note:
					</Typography>
					<Typography
						variant="subtitle2"
						color="textSecondary"
						sx={{
							mb: -2,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap'

						}}>
						{client.last_note?.note}
					</Typography>
					<br/>
				</>)}
			</Box>
		</Paper>
	);
};

export default ClientCard;
