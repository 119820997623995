
import React from 'react';
import { Tabs, Tab, Toolbar, AppBar, Box } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { UserMenu, Logout, LoadingIndicator } from 'react-admin';
import '../styles/Navbar.css';

const Navbar = () => {
	const location = useLocation();

	let currentPath = '/clients';
	if (matchPath('/clients/*', location.pathname)) {
		currentPath = '/clients';
	} else if (matchPath('/projects/*', location.pathname)) {
		currentPath = '/projects';
	} else if (matchPath('/contacts/*', location.pathname)) {
		currentPath = '/contacts';
	} else if (matchPath('/positions/*', location.pathname)) {
		currentPath = '/positions';
	}

	return (
		<Box component="nav" sx={{ flexGrow: 1 }}>
			<AppBar position="static" color="primary">
				<Toolbar variant="dense">
					<Box flex={1} display="flex" justifyContent="space-between">
						<Box display="flex" alignItems="center">
							<Box
								component="img"
								sx={{ height: 40, marginTop: 1.25, marginBottom: 1.25, marginRight: 1 }}
								src={
									require('../assets/img/clients-app-isologo.png')
								}
								alt="Clients app Isologo"
							/>
						</Box>
						<Box display="flex">
							<Tabs
								value={currentPath}
								aria-label="Navigation Tabs"
								indicatorColor="secondary"
								textColor="inherit"
							>
								<Tab
									label={'Clients'}
									component={Link}
									to="/clients"
									value="/clients"
								/>
								<Tab
									label={'Projects'}
									component={Link}
									to="/projects"
									value="/projects"
								/>
								<Tab
									label={'Positions'}
									component={Link}
									to="/positions"
									value="/positions"
								/>
								{/* <Tab
									label={'Contacts'}
									component={Link}
									to="/contacts"
									value="/contacts"
								/> */}
							</Tabs>
						</Box>
						<Box display="flex">
							<LoadingIndicator
								sx={{
									'& .RaLoadingIndicator-loader': {
										marginTop: 2
									},
									display: 'flex'
								}}
							/>
							<UserMenu className='user-menu'>
								<Logout />
							</UserMenu>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Navbar;
