import React from 'react';
import {
	ShowBase
} from 'react-admin';
import ProjectDetailContent from '../../components/projects/details/ProjectDetailContent';


const ProjectDetails = () => {

	return (
		<ShowBase>
			<ProjectDetailContent />
		</ShowBase>
	);
};

export default ProjectDetails;
