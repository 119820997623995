import React from 'react';
import { Box } from '@mui/material';

const PositionSkillsList = ({ skills }) => {

	return (
		<Box
			component="span"
			sx={{
				listStyle: 'none',
				padding: 0,
				margin: 0,
				display: 'inline-block',
			}}
		>
			{skills?.map((skill, index) => (
				<Box
					component="div"
					key={index}
					sx={{
						display: 'inline',
						'&:after': {
							content: '", "',
						},
						'&:last-child:after': {
							content: '""',
						},
					}}
				>
					{skill}
				</Box>
			))}
		</Box>
	);
};

export default PositionSkillsList;