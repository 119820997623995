import React from 'react';
import { Edit, Form, Toolbar, useGetList } from 'react-admin';
import { Box, CardContent, Stack, Avatar } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


import ContactForm from '../../components/contacts/create/ContactForm';
import { ContactAside } from '../../components/contacts/details/ContactAside';


const ContactEdit = () => {

	const { data: clients } = useGetList('clients');
	const { data: projects } = useGetList('projects');
	const clientsOptions = clients?.map(client => ({ id: client.id, name: client.name }));
	const projectsOptions = projects?.map(project => ({ id: project.id, name: project.project_name, client_id: project.client_id }));


	return (
		<Edit
			aside={<ContactAside link="show" />}
			actions={false}
			redirect="show"
		>
			<Form warnWhenUnsavedChanges>
				<CardContent>
					<Stack direction="row">
						<Avatar sx={{ mt: 1 }}>
							<PersonAddIcon />
						</Avatar>
						<Box ml={2} flex="1" maxWidth={796}>
							<ContactForm clientOptions={clientsOptions} projectOptions={projectsOptions} />
						</Box>
					</Stack>
				</CardContent>
				<Toolbar />
			</Form>
		</Edit>
	);
};

export default ContactEdit;
