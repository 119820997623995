import React from 'react';
import { TextInput, SelectInput, required, SelectArrayInput, regex } from 'react-admin';
import { skills } from '../../../constants/SelectOptions';
import { Stack } from '@mui/material';
import { keepOnlyLetters, formatInvoiceCode } from '../../../utils/formatters';
import { useController } from "react-hook-form";

const ProjectForm = ({clientOptions=[], projectStatusOptions=[] }) => {
	const { field } = useController({
		name: 'inv_code',
	});
	const validateInvCode =[regex(/^\d+(\.\d+)?$/, 'Must be a valid Invoice Code'), required()];

	const handleBlur = (event) => {
		const { value } = event.target;
		if (!value.includes('.') && value) {
			field.onChange(value+'.0');
		}
	};

	return (
		<>
			<TextInput source="project_name" validate={required()} fullWidth />
			<SelectInput
				source="client_id"
				choices={clientOptions}
				validate={required()}
				fullWidth
			/>
			<Stack direction="row" sx={{ gap: '2%' }}>
				<SelectInput
					source="status.id"
					label="Status"
					choices={projectStatusOptions || []}
					validate={required()}
					fullWidth
				/>
				<TextInput 
					source="inv_code"
					validate={validateInvCode}
					parse={formatInvoiceCode}
					format={formatInvoiceCode}
					fullWidth
					onBlur={handleBlur}
				/>
			</Stack>
			{/*<SelectInput source="project_contact" choices={selectContactOptions} validate={required()} fullWidth disabled={!clientSelectInput} />*/}
			<Stack direction="row" sx={{ gap: '2%' }}>
				<TextInput source="account_manager" validate={required()} format={keepOnlyLetters} parse={keepOnlyLetters} fullWidth />
				<TextInput source="sales_person" validate={required()} format={keepOnlyLetters} parse={keepOnlyLetters} fullWidth />
			</Stack>
			<SelectArrayInput
				source="tech_stack"
				label='Tech Stack'
				choices={skills}
				validate={required()}
				fullWidth
				defaultValue=''
			/>
		</>
	);
};

export default ProjectForm;
