import React from 'react';
import {
	TopToolbar,
	CreateButton
} from 'react-admin';

const PositionListActions = () => {
	return (
		<TopToolbar>
			<CreateButton
				variant="contained"
				label="New Position"
				sx={{ marginLeft: 2 }}
			/>
		</TopToolbar>
	);
};

export default PositionListActions;
