import * as React from 'react';
import {
	ShowBase
} from 'react-admin';
import ContactDetailContent from '../../components/contacts/details/ContactDetailContent';

const ContactDetails = () => (
	<ShowBase>
		<ContactDetailContent />
	</ShowBase>
);

export default ContactDetails;
