export const keepOnlyLetters = (str) => {
	if(!str) {
		return '';
	}
	return str.replace(/[^A-Za-z\s]+/g, '');
};


export const formatInvoiceCode = (str) => {
	if(!str) {
		return '';
	}
	return str.replace(/,/g, '.').replace(/[^\d.]|(?<=\..*)\./g, '');
};
